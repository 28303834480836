import { Routes, Route, useNavigate } from 'react-router-dom'
import React from 'react'
// import Login from './pages/Login/Login'
// import PortData from './pages/PortData/PortData'
// import Data from './pages/Data/Data'
// import Profile from './pages/Profile/Profile'
// import PasswordReset from './pages/Login/PasswordReset'
import { CommentProvider } from './contexts/CommentContext'
import { DataProvider } from './contexts/DataContext'
import { PortsProvider } from './contexts/PortsContext'
import Cancel from './pages/Login/Cancel'
import Layout from './components/Layout'
import HomeLayout from './components/HomeLayout'
import RequireAuth from './components/RequireAuth'
import Missing from './components/Missing'
import PersistLogin from './pages/Login/PersistLogin'
import { ErrorProvider } from './contexts/ErrorContext'
import Loading from './components/Loading'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './components/ErrorFallback'
const Login = React.lazy(() => import('./pages/Login/Login'))
const Ports = React.lazy(() => import('./pages/Ports/Ports'))
const PortData = React.lazy(() => import('./pages/PortData/PortData'))
const Data = React.lazy(() => import('./pages/Data/Data'))
const Profile = React.lazy(() => import('./pages/Profile/Profile'))
const PasswordReset = React.lazy(() => import('./pages/Login/PasswordReset'))

export default function App() {
    const navigate = useNavigate()
    return (
        <Routes>
            {/* public routes */}
            <Route path="/" element={<HomeLayout />}>
                <Route index element={<Login />} />
                <Route
                    path="/confirm"
                    element={
                        <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={() => navigate('/')}
                        >
                            <React.Suspense fallback={<Loading />}>
                                <Login />
                            </React.Suspense>
                        </ErrorBoundary>
                    }
                />
                <Route
                    path="/resetPassword"
                    element={
                        <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={() => navigate('/')}
                        >
                            <React.Suspense fallback={<Loading />}>
                                <PasswordReset />
                            </React.Suspense>
                        </ErrorBoundary>
                    }
                />
                <Route
                    path="/cancel"
                    element={
                        <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={() => navigate('/')}
                        >
                            <React.Suspense fallback={<Loading />}>
                                <Cancel />
                            </React.Suspense>
                        </ErrorBoundary>
                    }
                />
            </Route>

            {/* protected routes */}
            <Route path="/" element={<Layout />}>
                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth />}>
                        <Route
                            path="/ports"
                            element={
                                <ErrorBoundary
                                    FallbackComponent={ErrorFallback}
                                    onReset={() => navigate('/')}
                                >
                                    <React.Suspense fallback={<Loading />}>
                                        <ErrorProvider>
                                            <PortsProvider>
                                                <Ports />
                                            </PortsProvider>
                                        </ErrorProvider>
                                    </React.Suspense>
                                </ErrorBoundary>
                            }
                        />
                        <Route
                            path="/ports/:code"
                            element={
                                <ErrorBoundary
                                    FallbackComponent={ErrorFallback}
                                    onReset={() => navigate('/')}
                                >
                                    <React.Suspense fallback={<Loading />}>
                                        <ErrorProvider>
                                            <PortsProvider>
                                                <DataProvider>
                                                    <PortData />
                                                </DataProvider>
                                            </PortsProvider>
                                        </ErrorProvider>
                                    </React.Suspense>
                                </ErrorBoundary>
                            }
                        />
                        <Route
                            path="/ports/:code/:name"
                            element={
                                <ErrorBoundary
                                    FallbackComponent={ErrorFallback}
                                    onReset={() => navigate('/')}
                                >
                                    <React.Suspense fallback={<Loading />}>
                                        <ErrorProvider>
                                            <PortsProvider>
                                                <DataProvider>
                                                    <CommentProvider>
                                                        <Data />
                                                    </CommentProvider>
                                                </DataProvider>
                                            </PortsProvider>
                                        </ErrorProvider>
                                    </React.Suspense>
                                </ErrorBoundary>
                            }
                        />
                        <Route
                            path="User Profile"
                            element={
                                <ErrorBoundary
                                    FallbackComponent={ErrorFallback}
                                    onReset={() => navigate('/')}
                                >
                                    <React.Suspense fallback={<Loading />}>
                                        <ErrorProvider>
                                            <PortsProvider>
                                                <Profile />
                                            </PortsProvider>
                                        </ErrorProvider>
                                    </React.Suspense>
                                </ErrorBoundary>
                            }
                        />
                    </Route>
                </Route>
                {/* catch all */}
                <Route path="*" element={<Missing />} />
            </Route>
        </Routes>
    )
}
